var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "home-case-page" } },
    [
      _c("fb-loading", {
        attrs: { loading: _vm.isLoading, text: "加载案件列表中" },
      }),
      _c(
        "div",
        { staticClass: "home-case-list" },
        _vm._l(_vm.caseList, function (item) {
          return _c(
            "router-link",
            { key: item.caseId, attrs: { to: _vm.caseDetailLink(item) } },
            [
              _c(
                "div",
                { staticClass: "case-list-item van-hairline--bottom" },
                [
                  _c("div", { staticClass: "title" }, [
                    _c("span", { staticClass: "tag" }, [
                      _vm._v(_vm._s(item.caseAttrText)),
                    ]),
                    _vm._v(_vm._s(item.caseName) + " "),
                  ]),
                ]
              ),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }