<template>
  <div id="home-case-page">
    <fb-loading :loading="isLoading" text="加载案件列表中" />
    <div class="home-case-list">
      <router-link v-for="item in caseList" :key="item.caseId" :to="caseDetailLink(item)">
        <div class="case-list-item van-hairline--bottom">
          <div class="title">
            <span class="tag">{{ item.caseAttrText }}</span
            >{{ item.caseName }}
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'case-page',
  data() {
    return {
      title: '案件页',
      isLoading: false,
      caseList: [],
    }
  },
  metaInfo() {
    return {
      title: '案件',
    }
  },
  computed: {
    ...mapGetters('user', ['userId']),
  },
  methods: {
    async getCaseList() {
      this.isLoading = true
      const userId = this.userId
      try {
        const { data } = await this.$axios.get(
          `${this.$base}/lts/case/attrs3?userId=${userId}`
        )
        this.isLoading = false
        if (data && data.data) {
          this.caseList = data.data.content
        }
      } catch (err) {
        console.log(err)
      }
    },
    // 进入案件详情页
    caseDetailLink(item) {
      const caseId = item.caseId
      const path = `/casedetail/${caseId}/timeline`
      return { path }
    },
  },
  created() {
    // 获取案件列表
    this.userId && this.getCaseList()
  },
}
</script>

<style lang="stylus" scoped>
#home-case-page
  width 100%
  .home-case-list
    padding 0 15px
    a
      text-decoration none
      .case-list-item
        padding 12px
        .title
          font-size 14px
          color #666
          span.tag
            padding 0 10px
            font-size 12px
            color #1890ff
            border-radius 2px
            background #e6f7ff
            border-color #91d5ff
            margin-right 8px
</style>
